import styles from './Button.css'

export function ButtonLinkWhite({ href, dataX, children, layoutClassName = undefined, ariaLabel = undefined }) {
  return (
    <ButtonLinkBase
      color='white'
      {...{ href, dataX, ariaLabel, layoutClassName, children }}
    />
  )
}

export function ButtonLinkBlue({ href, dataX, children, layoutClassName = undefined, ariaLabel = undefined }) {
  return (
    <ButtonLinkBase
      color='blue'
      {...{ href, dataX, ariaLabel, layoutClassName, children }}
    />
  )
}

export function ButtonLinkDark({ href, dataX, children, layoutClassName = undefined, ariaLabel = undefined }) {
  return (
    <ButtonLinkBase
      color='dark'
      {...{ href, dataX, ariaLabel, layoutClassName, children }}
    />
  )
}

function ButtonLinkBase({ layoutClassName, color, dataX, href, children }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href?.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a className={cx(styles.componentLinkBase, layoutClassName, styles[color])} data-x={dataX} {...{ href, rel, target }}>
      {children}
    </a>
  )
}

export function Button({ layoutClassName, color, dataX, children, onClick, ariaLabel = undefined  }) {
  return (
    <button type='button' className={cx(styles.component, layoutClassName, styles[color])} data-x={dataX} aria-label={ariaLabel} {...{ onClick }} >
      {children}
    </button>
  )
}
