import { useForm } from '@kaliber/forms'
import { FormFieldConsentCheckbox, formFieldRenderers } from './FormField'
import { required, optional } from '@kaliber/forms/validation'
import { fileSize, fileExtension, checked, phone, email } from '/machinery/customValidation'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { pushToDataLayer, pushToDataLayerWithCallback } from '/machinery/tracking/pushToDataLayer'
import { FormFieldValid } from '@kaliber/forms/components'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { ref as storageRef, getStorage, uploadBytes } from 'firebase/storage'
import { LoaderSmall } from '/features/buildingBlocks/Loader'
import { useReportError } from '/machinery/ReportError'
import { getClientId } from '/machinery/tracking/getClientId'
import { makeSlug } from '/machinery/slug'
import { routeMap } from '/routeMap'
import { useMutation } from 'react-query'
import { useClientConfig } from '/machinery/ClientConfig'
import { getDatabase, push, ref, serverTimestamp, get } from 'firebase/database'
import { LinkBlue } from '/features/buildingBlocks/Link'

import styles from './ApplicationForm.css'

const unpublishedJobMessage = 'unpublished-job-message'
const acceptedFileTypes = ['pdf', 'docx', 'doc', 'txt']
const additionalFieldValidation = {
  email: [email],
  phone: [phone],
  attachment: [fileSize(20 * 1024 * 1024), fileExtension(acceptedFileTypes)]
}

export function ApplicationForm({ questions: questionsRaw, jobId, jobTitle, language, clientIdQuestionName, referrer }) {
  const { ga4Id } = useClientConfig()
  const questions = removeOptionalCoverLetter(questionsRaw)
  const { formFields, initialValues } = extractFormInformation(questions)
  const firebaseApp = useFirebaseApp()
  const { __ } = useTranslate()
  const reportError = useReportError()
  const trackedBeginCheckoutRef = React.useRef(false)

  const { mutate, data: message, isLoading, isError } = useMutation({
    mutationFn: handleSendApplication,
    onError: reportError,
    onSuccess: message => {
      const isSuccess = !message || message !== unpublishedJobMessage
      if (isSuccess) {
        handleOnSuccess()
      }
    }
  })

  const isUnpublishedJobMessage = message === unpublishedJobMessage

  const { form, submit } = useForm({
    fields: {
      ...formFields,
      consent: [required, checked]
    },
    initialValues: {
      ...initialValues,
      consent: false
    },
    onSubmit: handleSubmit
  })
  const { fields } = form

  return (
    <div className={styles.component}>
      <form className={styles.form} onSubmit={submit} noValidate onChange={handleCheckout}>
        {questions.map(({ label, required, ...question }) => {
          const Component = formFieldRenderers[question.type]

          return (
            <Component
              key={question.name}
              type={question.name === 'email' ? 'email' : 'text'}
              field={question.type === 'attachment'
                ? fields[question.name]
                : withTrackOnBlur(fields[question.name])
              }
              options={question.values}
              {... { label, required }}
            />
          )
        })}

        <FormFieldConsentCheckbox field={fields.consent} required>
          {__`form-consent-message`}{` `}
          <a className={styles.consentLink} href='/static/Bitvavo-Applicant-Privacy-Notice.pdf'>
            {__`form-consent-read-notice`}
          </a>
        </FormFieldConsentCheckbox>

        <FormFieldValid field={form} render={valid => (
          <div className={styles.submitButton}>
            <SubmitButton disabled={isError || !valid || isLoading || isUnpublishedJobMessage} {...{ isLoading }} />
          </div>
        )} />
      </form>

      {isError && <Error message={__`application-form-error-message`} />}
      {isUnpublishedJobMessage && <UnpublishedMessage />}
    </div>
  )

  function handleSubmit({ invalid, value }) {
    if (invalid) return

    const { consent, ...restFormValues } = value

    const { formValues, files, storageRefs } = Object.entries(restFormValues).reduce(
      (result, [fieldName, value]) => {
        if (value instanceof File) {
          result.files[fieldName] = value
          result.storageRefs[fieldName] = {
            extension: getExtension(value.name)
          }
        } else {
          const cleanFieldName = fieldName.includes('[]') ? fieldName.replace('[]', '') : fieldName
          result.formValues[cleanFieldName] =
            Array.isArray(value) ? { arrayValue: value } :
            typeof value === 'boolean' ? { booleanValue: value } :
            { stringValue: value }
        }

        return result
      },
      { formValues: {}, files: {}, storageRefs: {} }
    )

    mutate({ formValues, files, storageRefs, consent })
  }

  async function handleSendApplication({ formValues, files, storageRefs, consent }) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    const database = getDatabase(firebaseApp)
    const currentJobStatus = await getCurrentJobStatus(database)
    if (currentJobStatus && currentJobStatus !== 'live') return unpublishedJobMessage

    const clientId = clientIdQuestionName && await getClientId(ga4Id).catch(reportError)

    await storeFiles({ uid, files })

    await push(ref(database, 'services/application-processing-service/queue'), {
      formSubmitDate: serverTimestamp(),
      formValues,
      storageRefs,
      consent,
      uid,
      jobId,
      ...(clientId && { clientId: { questionName: clientIdQuestionName, value: clientId } }),
      ...(referrer) && { referrer },
    })
  }

  async function storeFiles({ uid, files }) {
    const storage = getStorage(firebaseApp)

    await Promise.all(
      Object.entries(files).map(
        async ([fieldName, file]) => {
          if (!file) return

          const uploadRef = storageRef(storage, `/uploads/${uid}/${jobId}/${fieldName}.${getExtension(file.name)}`)
          await uploadBytes(uploadRef, file)
        }
      )
    )
  }

  async function getCurrentJobStatus(database) {
    const jobStatus = (await get(ref(database, `jobPosts/${jobId}/status`))).val()
    return jobStatus
  }

  async function handleOnSuccess() {
    await pushToDataLayerWithCallback({ event: 'purchase' })

    window.location.href = routeMap.app.jobs.detail.thankYou({
      language,
      jobTitle: makeSlug({ input: jobTitle, language }),
      jobId
    })
  }

  function handleCheckout() {
    if (!trackedBeginCheckoutRef.current) {
      trackedBeginCheckoutRef.current = true
      pushToDataLayer({ event: 'begin_checkout' })
    }
  }
}

function extractFormInformation(questions) {
  return questions.reduce(
    (result, question) => ({
      formFields: {
        ...result.formFields,
        [question.name]: [question.required ? required : optional]
          .concat(
            additionalFieldValidation[question.type] || [],
            additionalFieldValidation[question.name] || []
          )
      },
      initialValues: {
        ...result.initialValues,
        [question.name]: (
          question.type === 'attachment' ? null :
          (question.type === 'multi_select' && question.values) ? [] :
          ''
        )
      }
    }),
    { formFields: {}, initialValues: {} }
  )
}

function SubmitButton({ isLoading, disabled }) {
  const { __ } = useTranslate()

  return (
    <button type='submit' className={cx(styles.componentSubmitButton, disabled && styles.isDisabled)} {...{ disabled }}>
      <>
        {isLoading && <LoaderSmall layoutClassName={styles.loader} />}
        {__`application-form-apply`}
      </>
    </button>
  )
}

function withTrackOnBlur(field) {
  return {
    ...field,
    eventHandlers: {
      ...field.eventHandlers,
      onBlur: (...args) => {
        field.eventHandlers.onBlur(...args)
        trackFieldBlur(field)
      }
    }
  }
}

function trackFieldBlur(field) {
  const fieldState = field.state.get()
  pushToDataLayer({
    event: `application_form_field_${fieldState.invalid ? 'failed' : 'completed'}`,
    metadata: { form: { field: field.name } }
  })
}

function Error({ message }) {
  return (
    <div className={styles.componentError}>
      {message}
    </div>
  )
}

function UnpublishedMessage() {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <>
      {__`application-form-unpublished-message-start`}
      <LinkBlue
        href={routeMap.app.jobs.index({ language })}
        dataX='link-to-jobsOverview'
      >
        {__`application-form-unpublished-message-link-label`}
      </LinkBlue>
      {__`application-form-unpublished-message-end`}
    </>
  )
}

function removeOptionalCoverLetter(questions) {
  return questions.filter(x => !(x.name === 'cover_letter' && !x.required))
}

function getExtension(name) { return name.split('.').slice(-1).join() }

